import React from 'react'
import anylogger from '@app/anylogger'
import { Flex, Text } from '@app/muiplus'
import Link from './Link'
import { PageReferenceData } from '@app/mdxsource'

const log = anylogger('PageLinks')

interface PageLinksProps {
	pageData: PageReferenceData[]
}
export const PageLinks = React.forwardRef(function PageLinks(props: PageLinksProps, ref: any) {
	// we can't 'request' child item metadata from here because this is now in the browser.
	// it must be passed to us.  But, we don't want to provide data to all pages if they don't require it
	// perhaps an MDX gray matter tag could request it, and if there are no child items provided,
	// the controls can display data indicating that the tag is required.
	// const children = GetChildren(path)
	const { pageData } = props
	const getPageLinks = () => {
		if (!pageData) return <Text>Child Data was not returned. Did you include the includeChildData property?</Text>
		if (pageData.length) {
			return pageData.map((data, index) => {
				const link = data.link ? data.link : ''
				return (
					<Link key={index} href={link}>
						{data.title}
					</Link>
				)
			})
		} else {
			return <Text>There are no child pages to display</Text>
		}
	}
	return (
		<Flex m="auto" f="c">
			{getPageLinks()}
		</Flex>
	)
})
