import { ClientProxy } from '@app/clientserverutils'
import { GoogleEvent, GoogleEventList, IGoogleCalendar } from '@app/googlecalendar'
import {
	Buttons,
	EditDialog,
	FieldDefinition,
	Flex,
	FlexC,
	useCurrentBreakpoint,
	H4,
	ICustomDialogCallbackProps,
	Save,
	Text,
	useBreakpoint,
	useBreakpointValues,
	useModal
} from '@app/muiplus'
import { Box, Button, IconButton, useTheme, lighten, Dialog, DialogTitle, DialogContent, Popover, Paper, alpha } from '@mui/material'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
import { useComponentSize, useCurrentRef, useEvent, useKeyBinder, useLocalStorageOrState } from '@app/hooks'
import {
	adjustDate,
	adjustMonth,
	datesAreEqual,
	formatDateTime,
	getElementFont,
	getTextWidth,
	logRenderReason,
	longMonths,
	parseDateTime
} from '@app/utils'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight'
import Close from '@mui/icons-material/Close'
import ModeEdit from '@mui/icons-material/ModeEdit'
import { usePermission } from 'src/services/usePermission'

const log = anylogger('Calendar')

// interface ICache<K extends string | number | symbol, T> {
// 	getValue(key: K): T | undefined
// 	hasValue(key: K): boolean
// 	setValue(key: K, val: T): void
// }
class Cache<K extends string | number | symbol, T> {
	cache: Record<K, Promise<T>> = {} as Record<K, Promise<T>>
	async getValue(key: K): Promise<T> {
		let res = this.cache[key]
		if (typeof res == 'undefined') {
			res = this.retrieveValue(key)
			this.cache[key] = res
		}
		return await res
	}
	retrieveValue(key: K): Promise<T> {
		throw new Error('Method not implemented.')
	}
	hasValue(key: K): boolean {
		return typeof this.cache[key] != 'undefined'
	}
	setValue(key: K, val: T): void {
		this.cache[key] = Promise.resolve(val)
	}
}
class GoogleEventCache extends Cache<string, GoogleEventList> {
	api: IGoogleCalendar
	constructor(api: IGoogleCalendar) {
		super()
		this.api = api
	}
	retrieveValue(key: string): Promise<GoogleEventList> {
		const end = adjustDate(key, 42)
		return this.api.listEvents({
			timeMin: key + 'T00:00:00Z',
			timeMax: end.toISOString()
		})
	}
}

class Context {
	api!: IGoogleCalendar
	getEventsForDate!: (startDate: string) => Promise<GoogleEventList>
	selectedDate!: string
	setSelectedDate!: (date: Date | string) => void
	getStartDateForMonth!: (date: string) => string
	formatDate!: (date: string | Date) => string
	editEvent!: (event: GoogleEvent) => Promise<void>
}
export const CalendarContext = createContext(new Context())

interface CalendarControllerProps {}
export default function CalendarController(props: CalendarControllerProps) {
	const [api] = useState<IGoogleCalendar>(new ClientProxy(new IGoogleCalendar(), '/api/calendar') as IGoogleCalendar)
	const initDate = formatDateTime(new Date(), 'yyyy-mm-dd')
	const [selectedDate, setSelectedDateInternal] = useLocalStorageOrState<string>('Calendar.SelectedDate', initDate)
	// const [selectedDate, setSelectedDateInternal] = useState(() => formatDateTime(new Date(), 'yyyy-mm-dd'))

	const [dateCache] = useState<GoogleEventCache>(new GoogleEventCache(api))
	const { customDialog } = useModal()

	const formatDate = useCallback((date: Date | string) => {
		return formatDateTime(date, 'yyyy-mm-dd')
	}, [])
	const setSelectedDate = useCallback(
		(date: Date | string) => {
			setSelectedDateInternal(formatDate(date))
		},
		[formatDate, setSelectedDateInternal]
	)
	const editEvent = useCallback(
		async (event: GoogleEvent) => {
			const fields = FieldDefinition.initList([
				['Title', 'summary', 'string'],
				['Description', 'description', ['multiline', { context: { minRows: 5, maxRows: 10 } }]]
				// ['Description', 'description', 'string'],
				// ['Date Taken', 'dateTaken', ['date', { disabled: isTrue(meta.dtRO) }]],
				// ['Date RO', 'dtRO', 'boolean'],
				// ['Author', 'author', ['string', { disabled: true }]],
			])

			const res = await customDialog({
				title: 'Calendar Event',
				dialog: <EditDialog<any> data={event} fields={fields} />,
				buttons: [Buttons.Save, Buttons.Discard]
			})

			if (res.result == Save) {
				api.updateEvent(res.data)
			}
		},
		[api, customDialog]
	)
	const nextDay = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, 1))
	}, [selectedDate, setSelectedDate])
	const prevDay = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, -1))
	}, [selectedDate, setSelectedDate])
	const nextWeek = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, 7))
	}, [selectedDate, setSelectedDate])
	const prevWeek = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, -7))
	}, [selectedDate, setSelectedDate])
	const nextMonth = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, 28))
	}, [selectedDate, setSelectedDate])
	const prevMonth = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, -28))
	}, [selectedDate, setSelectedDate])

	const { useKeyBinding } = useKeyBinder()
	useKeyBinding('ArrowRight', nextDay)
	useKeyBinding('ArrowLeft', prevDay)
	useKeyBinding('ArrowDown', nextWeek)
	useKeyBinding('ArrowUp', prevWeek)
	useKeyBinding('PageDown', nextMonth)
	useKeyBinding('PageUp', prevMonth)

	const getStartDateForMonth = useCallback(
		(date: string) => {
			const { wd, mm: selMonth } = parseDateTime(date)
			const sunday = formatDate(adjustDate(date, wd * -1))
			const { dd, mm: sunMonth } = parseDateTime(sunday)
			const weeks = selMonth == sunMonth ? Math.ceil(dd / 7) : 0
			const subDays = weeks * 7 + wd
			return formatDate(adjustDate(date, -1 * subDays))
		},
		[formatDate]
	)

	const getEventsForDate = useCallback(
		async (date: string) => {
			const startDate = getStartDateForMonth(date)

			const events = await dateCache.getValue(formatDate(startDate))
			const prevDate = adjustMonth(date, -1)
			const prevStart = getStartDateForMonth(formatDate(prevDate))
			const nextDate = adjustMonth(date, 1)
			const nextStart = getStartDateForMonth(formatDate(nextDate))

			dateCache.getValue(formatDate(prevStart))
			dateCache.getValue(formatDate(nextStart))

			return events
		},
		[dateCache, formatDate, getStartDateForMonth]
	)

	const ctx: Context = { api, getEventsForDate, selectedDate, setSelectedDate, editEvent, getStartDateForMonth, formatDate }

	return (
		<CalendarContext.Provider value={{ ...ctx } as Context}>
			<CalendarView />
		</CalendarContext.Provider>
	)
}

export function CalendarView() {
	const { getEventsForDate, selectedDate, setSelectedDate, getStartDateForMonth, formatDate } = useContext(CalendarContext)
	const [events, setEvents] = useState<Record<string, GoogleEventList>>({})
	const [ref, setRef] = useCurrentRef<HTMLElement>()
	const gap = useBreakpointValues('2px', { mobile: '1px' })

	const startDate = getStartDateForMonth(selectedDate)

	const createKey = useCallback((e: GoogleEvent) => {
		let ed = e.start.dateTime
		if (!ed) ed = e.start.date + 'T00:00:00'
		const dt = parseDateTime(ed!)
		return `${dt.mm}-${dt.dd}`
	}, [])
	const onWheel = useCallback(
		(e: any) => {
			let days = 28
			if (e.deltaY < 0) days = days * -1
			setSelectedDate(adjustDate(selectedDate, days))
		},
		[selectedDate, setSelectedDate]
	)
	useEvent(ref, 'wheel', onWheel)

	useEffect(() => {
		getEventsForDate(selectedDate).then((events) => {
			const eventDict = events.reduce((res, e, idx) => {
				const key = createKey(e)
				if (!res[key]) res[key] = []
				res[key].push(e)
				return res
			}, {} as any)
			setEvents(eventDict)
		})
	}, [createKey, getEventsForDate, selectedDate])

	const getDays = useCallback(() => {
		let res = []
		let curDate = startDate
		for (let week = 0; week < 6; week++) {
			for (let day = 0; day < 7; day++) {
				let dayEvents: GoogleEventList | undefined = undefined
				const dt = parseDateTime(curDate)
				const key = `${dt.mm}-${dt.dd}`
				if (events) {
					dayEvents = events[key]
				}

				res.push(<CalendarMonthDay key={key} date={curDate} events={dayEvents} />)
				curDate = formatDate(adjustDate(curDate, 1))
			}
		}
		return res
	}, [startDate, events, formatDate])
	const bkp = useCurrentBreakpoint()
	const bgColor = bkp == 'mobile' ? 'lightgray' : undefined
	return (
		<FlexC fill ref={setRef} fontSize="12px">
			<CalendarHeader />
			<Flex fill>
				<Box
					display="grid"
					width="100%"
					bgcolor={bgColor}
					gridTemplateColumns="repeat(7,1fr)"
					gridTemplateRows="repeat(6,1fr)"
					gap={gap}
				>
					{getDays()}
				</Box>
			</Flex>
		</FlexC>
	)
}
function CalendarHeader({}: any) {
	const { selectedDate, setSelectedDate } = useContext(CalendarContext)
	const [textRef, setTextRef] = useCurrentRef<HTMLElement>()
	const [monthWidth, setMonthWidth] = useState(0)

	useEffect(() => {
		if (!textRef) return
		const font = getElementFont(textRef)
		let width = 0
		for (let ii = 0; ii < longMonths.length; ii++) {
			width = Math.max(width, getTextWidth(longMonths[ii], font))
		}
		setMonthWidth(width)
	}, [textRef])

	const { mmmm, yyyy } = parseDateTime(selectedDate)

	const prevYear = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, -365))
	}, [selectedDate, setSelectedDate])
	const nextYear = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, 365))
	}, [selectedDate, setSelectedDate])
	const prevMonth = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, -30))
	}, [selectedDate, setSelectedDate])
	const nextMonth = useCallback(() => {
		setSelectedDate(adjustDate(selectedDate, 30))
	}, [selectedDate, setSelectedDate])
	return (
		<Flex fontSize="20px">
			<Button onClick={(e: any) => setSelectedDate(new Date())}>Today</Button>
			<Flex fill alignItems="center" justifyContent="center">
				<IconButton onClick={prevYear}>
					<KeyboardDoubleArrowLeft />
				</IconButton>
				<IconButton onClick={prevMonth}>
					<KeyboardArrowLeft />
				</IconButton>
				<Text width={`${monthWidth}px`} ref={setTextRef} textAlign="center">
					{mmmm}
				</Text>
				<Text>{yyyy}</Text>
				<IconButton onClick={nextMonth}>
					<KeyboardArrowRight />
				</IconButton>
				<IconButton onClick={nextYear}>
					<KeyboardDoubleArrowRight />
				</IconButton>
			</Flex>
		</Flex>
	)
}

function CalendarMonthDay({ date, events }: { date: string; events: GoogleEventList | undefined }) {
	const { selectedDate: selectedDateStr, setSelectedDate } = useContext(CalendarContext)
	const [detailEvent, setDetailEvent] = useState<GoogleEvent | undefined>(undefined)
	const [ref, setRef] = useCurrentRef<HTMLElement>()

	const thisDate = parseDateTime(date)
	const today = parseDateTime(new Date())
	const selectedDate = parseDateTime(selectedDateStr)
	const theme = useTheme()
	const onCloseDetails = useCallback(() => {
		setDetailEvent(undefined)
	}, [])
	const eventClick = useCallback((e: GoogleEvent) => {
		setDetailEvent(e)
	}, [])

	const getEvents = useCallback(() => {
		if (!events) return null
		return events.map((e: GoogleEvent, idx: number) => {
			return <CalendarEvent key={idx} event={e} onClick={eventClick} isDetailed={e == detailEvent} />
		})
	}, [detailEvent, eventClick, events])
	const setDate = useCallback(() => {
		setSelectedDate(date)
	}, [date, setSelectedDate])
	let bgColor = 'white'
	log('theme.palette.action.disabledBackground', theme.palette.action.disabledBackground)

	if (thisDate.mm != selectedDate.mm) bgColor = theme.palette.action.disabledBackground
	else if (datesAreEqual(thisDate, selectedDate)) bgColor = lighten(theme.palette.primary.light, 0.7)
	const bkp = useCurrentBreakpoint()
	let borderProps = {}
	if (bkp == 'mobile') {
		borderProps = { borderRadius: '0px', gap: '0px' }
		// if (thisDate.mm != selectedDate.mm) bgColor = alpha('#FFFFFF', 0.12)
	} else {
		borderProps = { border: 'solid 1px gray', borderRadius: '0.5em' }
	}

	const dateBgColor = datesAreEqual(thisDate, today) ? theme.palette.primary.light : undefined
	return (
		<FlexC ref={setRef} p="auto" {...borderProps} bgcolor={bgColor} onClick={setDate} minWidth="0" minHeight="0">
			<Box
				mx="auto"
				bgcolor={dateBgColor}
				// px="0.25em"
				borderRadius="1000%"
				fontSize="1.3em"
				fontWeight="bold"
				sx={{
					aspectRatio: '1/1',
					textAlign: 'center'
				}}
			>
				{thisDate.dd}
			</Box>
			<FlexC fill>{getEvents()}</FlexC>
			<CalendarDetails event={detailEvent} onClose={onCloseDetails} anchorEl={ref} />
		</FlexC>
	)
}

interface CalendarEventProps {
	event: GoogleEvent
	onClick: (e: GoogleEvent) => void
	isDetailed: boolean
}
function CalendarEvent({ event, onClick, isDetailed }: CalendarEventProps) {
	const theme = useTheme()

	const dt = event.start.dateTime || event.start.date
	const time = event.start.dateTime ? formatDateTime(event.start.dateTime, 'hh:nn') + ' ' : ''
	let dateStr = formatDateTime(dt!, 'wdl mmmm dd, yyyy')
	if (time) dateStr = dateStr + ' - ' + time

	let tooltip = event.summary
	tooltip += '\n' + dateStr
	if (event.description) tooltip += '\n' + event.description
	if (isDetailed) tooltip = ''
	let desc = event.summary
	const bkp = useCurrentBreakpoint()
	if (bkp != 'mobile') desc = time + desc
	const bg = lighten(theme.palette.primary.light, 0.5)
	const showDetails = () => {
		onClick(event)
	}
	let eventProps = {}
	if (bkp == 'mobile') {
	} else {
		eventProps = {
			px: '0.25em',
			mx: '0.25em',
			textOverflow: 'ellipsis'
		}
	}
	return (
		<Box
			{...eventProps}
			overflow="hidden"
			whiteSpace="nowrap"
			borderRadius="0.25em"
			bgcolor={bg}
			tooltip={tooltip}
			onClick={showDetails}
			sx={{
				cursor: 'pointer'
			}}
		>
			{desc}
		</Box>
	)
}
interface CalendarDetailsProps {
	anchorEl: HTMLElement | null
	event: GoogleEvent | undefined
	onClose: () => void
}
function CalendarDetails(props: CalendarDetailsProps) {
	const { event, onClose, anchorEl } = props
	const { editEvent } = useContext(CalendarContext)

	const perm = usePermission()

	const edit = useCallback(() => {
		if (!event) return null
		editEvent(event)
	}, [editEvent, event])

	const getDetails = useCallback(() => {
		if (!event) return null
		const dt = event.start.dateTime || event.start.date
		const time = event.start.dateTime ? formatDateTime(event.start.dateTime, 'hh:nn') + ' ' : ''
		let dateStr = formatDateTime(dt!, 'wdl mmmm dd, yyyy')
		if (time) dateStr = dateStr + ' - ' + time
		let res = <Text>{dateStr}</Text>
		let desc = null
		if (event.description) desc = <Text>{event.description}</Text>
		return (
			<>
				{res}
				{desc}
			</>
		)
	}, [event])

	if (!event) return null

	const editButton = perm.isCalendarEditor ? (
		<IconButton onClick={edit}>
			<ModeEdit />
		</IconButton>
	) : null

	return (
		<Popover
			open={Boolean(event)}
			elevation={24}
			onClose={onClose}
			slotProps={{ paper: { sx: { borderRadius: '1em' } } }}
			sx={{
				borderRadius: '1000%'
				// maxWidth: '1000px'
			}}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center'
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
		>
			<DialogTitle>
				<Flex justifyContent="space-between">
					<Text>{event.summary}</Text>
					<Flex alignItems="start">
						{editButton}
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</Flex>
				</Flex>
			</DialogTitle>

			<DialogContent
				sx={{
					maxWidth: '800px'
				}}
			>
				{getDetails()}
			</DialogContent>
		</Popover>
	)
}

interface CalendareEditDialogProps extends ICustomDialogCallbackProps {
	event: GoogleEvent
}
function CalendarEditDialog({ event, dataChanged }: CalendareEditDialogProps) {
	return <FlexC>Content</FlexC>
}
