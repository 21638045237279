import React, { useEffect, useReducer, useState } from 'react'
import anylogger from '@app/anylogger'
import { Flex, useBreakpoint } from '@app/muiplus'
import { useSwipeable } from 'react-swipeable'
import { Carousel } from '@app/muiplus'
import { PageReferenceData } from '@app/mdxsource'
import { Card } from './Card'
import { getCssStyle, getPixelSize } from '@app/utils'
import { useCurrentRef } from '@app/hooks'

const log = anylogger('CardCarousel')

interface CardCarouselProps {
	slideInterval: number
	maxSlidesToShow: number
	slideWidth: number
	pageData: PageReferenceData[]
	picPos?: 'left' | 'right' | 'top' | 'bottom'
	picPercent?: number
	gap?: string
}

export const CardCarousel = React.forwardRef(function CardCarousel(props: CardCarouselProps, passedRef: any) {
	let {
		slideInterval = 5,
		maxSlidesToShow = 2,
		slideWidth = 300,
		pageData,
		picPos = 'top',
		picPercent = 50,
		gap = '0.5rem',
		...rest
	} = props
	let cardProps = { picPos, picPercent, height: '100%' }

	const getCards = () => {
		return pageData.map((page, idx) => {
			return <Card key={idx} {...cardProps} maxWidth={`${slideWidth}px`} pageData={page} mx={gap} />
		})
		// for testing carousel alignment
		// return pageData.map((page, idx) => {
		// 	return (
		// 		<Flex
		// 			key={idx}
		// 			width={`${slideWidth}px`}
		// 			mx={`${gapPx / 2}px`}
		// 			sx={{
		// 				border: '1px solid red'
		// 			}}
		// 		>
		// 			{idx}
		// 		</Flex>
		// 	)
		// })
	}
	const isMobile = useBreakpoint('mobile')
	if (isMobile) maxSlidesToShow = 1

	return (
		<Flex>
			<Carousel
				slideWidth={slideWidth}
				slidesPresented={maxSlidesToShow}
				interval={slideInterval * 1000}
				justifyContent="center"
				mx="auto"
			>
				{getCards()}
			</Carousel>
		</Flex>
	)
})
