import React from 'react'
import anylogger from '@app/anylogger'
import { Flex } from '@app/muiplus'

const log = anylogger('IFrame')

export const IFrame = React.forwardRef(function IFrame(props: any, ref: any) {
	return (
		<Flex justifyContent="center">
			<iframe {...props} frameBorder="0" loading="lazy"></iframe>
		</Flex>
	)
})
