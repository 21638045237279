import React from 'react'
import anylogger from '@app/anylogger'
import { ContrastBackgroundBox, H5 } from '@app/muiplus'

const log = anylogger('Loading')

interface LoadingProps {}
function Loading(props: LoadingProps) {
	return (
		<ContrastBackgroundBox m="auto" p="1em" borderRadius="50%/100%">
			<H5>Loading...</H5>
		</ContrastBackgroundBox>
	)
}
export { Loading }
