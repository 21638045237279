import { ICloudObject, ICloudStorage } from '@app/cloudstorage'
import { ColumnMapper } from '@app/muiplus'
import anylogger from '@app/anylogger'
import { ListViewProviderBase } from '@app/muiplus'

const log = anylogger('ICloudStorageProvider')

export class CloudStorageListViewProvider extends ListViewProviderBase<ICloudObject> {
	columns: ColumnMapper
	constructor(items: ICloudObject[]) {
		super(items)
		this.columns = new ColumnMapper([
			['Bucket', 'bucketId'],
			['Id', 'id'],
			['Type', 'type'],
			['Name', 'name'],
			['Size', 'size'],
			['Content Type', 'contentType'],
			['ETag', 'etag'],
			['Updated', 'updated'],
			['Created', 'created']
		])
	}
	getAllTitles() {
		return this.columns.getAllTitles()
	}
	createRow(item: ICloudObject) {
		return this.columns.getValues(item)
	}
}
