import React from 'react'
import anylogger from '@app/anylogger'
import { Card } from './Card'
import NextLink from 'next/link'
import { PageReferenceData } from '@app/mdxsource'
import { Flex, Text } from '@app/muiplus'

const log = anylogger('ChildLinks')

interface PageCardsProps {
	pageData: PageReferenceData[]
	maxWidth?: string
	picPos?: 'left' | 'right' | 'top' | 'bottom'
	picPercent?: number
}
export const PageCards = React.forwardRef(function PageCards(props: PageCardsProps, ref: any) {
	const { pageData, ...rest } = props
	const getPageLinks = () => {
		if (!pageData) return <Text>Child Data was not returned. Did you include the includeChildData property?</Text>
		if (pageData.length) {
			return pageData.map((data, index) => {
				// log('data', data)

				return <Card {...rest} key={index} pageData={data}></Card>
			})
		} else {
			return <Text>There are no child pages to display</Text>
		}
	}
	return (
		<Flex f="rw" justifyContent="center" m="auto">
			{getPageLinks()}
		</Flex>
	)
})
