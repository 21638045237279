import { ContrastBackgroundBox, H5 } from '@app/muiplus'

function NotAuthorized() {
	return (
		<ContrastBackgroundBox m="auto" p="1em" borderRadius="50%/100%">
			<H5>You are not authorized to view this page.</H5>
		</ContrastBackgroundBox>
	)
}

export { NotAuthorized }
