import { OptimizedImage as Image } from '../components/OptimizedImage'
import { PageLinks } from 'components/PageLinks'
import { IFrame } from 'components/IFrame'
import { Card } from 'components/Card'
import { PageCards } from './PageCards'
import { CardCarousel } from './CardCarousel'
import { Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material'

export interface MdxFrontMatter {
	title?: string
	prependTitle?: boolean
	summary?: string
	prependSummary?: boolean
	summaryImage?: string
	maxContentWidth?: string
	[key: string]: any
}

export const MdxComponents = {
	table: Table,
	thead: TableHead,
	tbody: TableBody,
	tr: TableRow,
	td: TableCell,
	th: TableCell,
	Image,
	PageLinks,
	IFrame,
	Card,
	PageCards,
	CardCarousel
}
