import React from 'react'
import anylogger from '@app/anylogger'
import { Flex, FlexC } from '@app/muiplus'
import Head from 'next/head'
import { MdxComponents } from './mdxComponents'
import { ErrorBoundary } from './ErrorBoundary'
import { MDXRemote } from 'next-mdx-remote'

const log = anylogger('MdxView')

interface MdxViewProps {
	source: string
	data: any
}
export const MdxView = React.forwardRef(function MdxView(props: MdxViewProps, ref: any) {
	let { source, data } = props
	let { title, summary, maxContentWidth } = data

	let maxWidth = '1024px'

	if (maxContentWidth) {
		if (maxContentWidth.toLowerCase() === 'fullscreen') maxWidth = '100%'
		else maxWidth = maxContentWidth
	} else maxWidth = '800px'

	return (
		<ErrorBoundary>
			<Flex ref={ref} px="10px" maxWidth={maxWidth} mx="auto">
				<Head>
					<title>{title}</title>
					<meta name="description" key="description" content={summary} />
				</Head>
				<div>
					{/* @ts-ignore */}
					<MDXRemote {...source} components={MdxComponents} />
				</div>
			</Flex>
		</ErrorBoundary>
	)
})
