import React from 'react'
import anylogger from '@app/anylogger'
import { Box } from '@mui/material'

const log = anylogger('Image')

interface ImageProps {}
export const Image = React.forwardRef(function Image(props: any, ref: any) {
	let { src, width, height, m, sx, ...rest } = props
	if (!m) m = '0.5em'

	return <Box component="img" src={src} width={width} height={height} m={m} sx={{ ...sx }} {...rest}></Box>
})
