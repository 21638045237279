import React, { ReactNode } from 'react'
import { ReconciledItem, ReconciledItemList, ReconcileUpdateStatus } from '@app/utils'
import { Flex, ProviderRowData, Text, TitleDefinition } from '@app/muiplus'
import { ListViewProviderBase } from '@app/muiplus'
import { memo } from 'react'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import CircleOutlined from '@mui/icons-material/CircleOutlined'
import DoneOutlined from '@mui/icons-material/DoneOutlined'
import Delete from '@mui/icons-material/Delete'
import anylogger from '@app/anylogger'
import Autorenew from '@mui/icons-material/Autorenew'
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlined from '@mui/icons-material/ErrorOutlined'
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline'

const log = anylogger('ReconciledItemProvider')

export class ReconciledItemProvider<T = any> extends ListViewProviderBase<ReconciledItem<T>> {
	baseProvider: ListViewProviderBase<T>
	getAllTitles() {
		if (!this.baseProvider.getAllTitles) return []
		const titles = this.baseProvider.getAllTitles()
		if (!titles.length) return []
		return [{ name: 'Status' }, { name: 'Changes', width: '200px' }, ...titles]
	}

	constructor(items: ReconciledItemList<T>, baseProviderType: typeof ListViewProviderBase<T>) {
		super(items)
		this.baseProvider = new baseProviderType([])
	}
	customCompare = (titleDef: TitleDefinition, a: ReconciledItem<T>, b: ReconciledItem<T>, valA: ReactNode, valB: ReactNode) => {
		if (titleDef.name == 'Status') {
			let res = a.status.localeCompare(b.status)
			return res
		} else if (titleDef.name == 'UpdateStatus') {
			let res = a.updateStatus.localeCompare(b.updateStatus)
			return res
		} else if (titleDef.name == 'Changes') {
			let res = a.changeText.localeCompare(b.changeText)
			return res
		} else return undefined
	}
	addedMap: Record<ReconcileUpdateStatus, string> = {
		'': 'Need to Add',
		'Updating...': 'Adding',
		Updated: 'Added',
		Error: 'Error Adding'
	}
	deletedMap: Record<ReconcileUpdateStatus, string> = {
		'': 'Need to Delete',
		'Updating...': 'Deleting',
		Updated: 'Deleted',
		Error: 'Error Delting'
	}
	changedMap: Record<ReconcileUpdateStatus, string> = {
		'': 'Need to Update',
		'Updating...': 'Updating',
		Updated: 'Updated',
		Error: 'Error Updating'
	}

	createRow(reconciled: ReconciledItem<T>, idx: number): ProviderRowData {
		let changeText = ''
		switch (reconciled.status) {
			case '':
				changeText = 'No Changes Needed'
				break
			case 'Added':
				changeText = this.addedMap[reconciled.updateStatus]
				break
			case 'Deleted':
				changeText = this.deletedMap[reconciled.updateStatus]
				break
			case 'Changed':
				changeText = this.changedMap[reconciled.updateStatus]
				break
		}

		const item = reconciled.Item()
		const baseColumns = this.baseProvider.createRow(item, idx)
		const importStatus = <ImportStatus key={idx} item={reconciled} changeText={changeText} />
		let toolTip = reconciled.changeText
		if (reconciled.updateStatus == 'Error') toolTip = toolTip + '\n' + reconciled.error.toString()
		const changeField = (
			<Text key={idx} tooltip={toolTip}>
				{changeText}
			</Text>
		)
		if (Array.isArray(baseColumns)) {
			return [{ value: changeText, displayValue: importStatus }, { value: changeText, displayValue: changeField }, ...baseColumns]
		} else {
			let res = { ...baseColumns }
			res['Status'] = { value: changeText, displayValue: importStatus }
			res['Changes'] = { value: changeText, displayValue: changeField }
			return res
		}
	}
}

interface ImportStatusProps {
	item: ReconciledItem
	changeText: string
}
export const ImportStatus = memo(function ImportStatus(props: ImportStatusProps, ref: any) {
	let { item, changeText } = props
	let status
	let updateStatus
	switch (item.updateStatus) {
		case '':
			break
		case 'Updating...':
			updateStatus = <Autorenew fontSize="inherit" />
			break
		case 'Updated':
			updateStatus = <CheckCircleOutline color="success" fontSize="inherit" />
			break
		case 'Error':
			updateStatus = <ErrorOutlined color="error" fontSize="inherit" />
			break
	}

	if (item.status == '') {
		status = <DoneOutlined fontSize="inherit" />
	} else if (item.status == 'Deleted') {
		status = <RemoveCircleOutline color="error" fontSize="inherit" />
	} else if (item.status == 'Added') {
		status = <AddCircleOutline color="success" fontSize="inherit" />
	} else if (item.status == 'Changed') {
		status = <CircleOutlined fontSize="inherit" />
	}
	if (['Changed', 'Deleted'].includes(item.status)) changeText = changeText + '\n' + item.changeText

	return (
		<Flex tooltip={changeText} justifyContent="center" alignItems="center">
			{status}
			{updateStatus}
		</Flex>
	)
})
