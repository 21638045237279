import {
	GoogleCalendar,
	GoogleCalendarList,
	GoogleEvent,
	GoogleEventList,
	IGoogleCalendar,
	ListEventParams,
	UpdateEventParams
} from './IGoogleCalendar'
import { AccessTokenProvider } from '@app/googletokenprovider'
import { ObjectStreamWriter } from '@app/clientserverutils'
import { AddTo, Guid, ObjectAdapter, sleep } from '@app/utils'
const querystring = require('querystring')
import anylogger from '@app/anylogger'
import { ServerResponse } from 'http'
import { NextApiResponse } from 'next'

const log = anylogger('GoogleContacts')

const baseUrl = 'https://www.googleapis.com/calendar/v3/'
const SCOPES = ['https://www.googleapis.com/auth/calendar']
const PersonFields = 'names,emailAddresses,phoneNumbers,relations,externalIds,memberships,userDefined,biographies'
type ProcessResponse<T> = (value: Response) => Promise<T>

export class GoogleCalendarApi implements IGoogleCalendar {
	authProvider: AccessTokenProvider
	calendarId: string | undefined

	constructor(authProvider: AccessTokenProvider, calendarId?: string) {
		this.authProvider = authProvider
		this.calendarId = calendarId
	}
	listCalendars = (): Promise<GoogleCalendarList> => {
		const getParams = () => {
			let res: any = {}
			return res
		}
		const processResponse = async (response: Response) => {
			const json = await response.json()

			let calendars = json.items
			return calendars
		}
		return this.ExecuteRequest(`users/me/calendarList`, getParams, processResponse)
	}
	listEvents = (params: ListEventParams): Promise<GoogleEventList> => {
		const calendarId = this.getCalendarId(params)
		const getParams = () => {
			let res: any = {}
			res.eventTyptes = ['default']
			res.singleEvents = true
			if (params.timeMin) res.timeMin = params.timeMin
			if (params.timeMax) res.timeMax = params.timeMax
			return res
		}
		const processResponse = async (response: Response) => {
			const json = await response.json()

			let calendars = json.items
			return calendars
		}
		return this.ExecuteRequest(`calendars/${calendarId}/events`, getParams, processResponse)
	}
	updateEvent = (event: GoogleEvent, params?: UpdateEventParams): Promise<void> => {
		const calendarId = this.getCalendarId(params || {})
		log('event', event)

		const getParams = () => {
			let res: any = {}
			return res
		}
		const getBody = () => {
			return JSON.stringify(event)
		}

		const processResponse = async (response: Response) => {
			const json = await response.json()

			let calendars = json.items
			return calendars
		}
		return this.ExecuteRequest(`calendars/${calendarId}/events/${event.id}`, getParams, processResponse, 'put', undefined, getBody)
	}
	getCalendarId(params: any) {
		const calendarId = params.calendarId ? params.calendarId : this.calendarId
		if (!calendarId) throw new Error('You muse specify the calendarId')
		return calendarId
	}
	async ExecuteRequest<T>(
		url: string,
		getParams: () => any,
		processResponse: ProcessResponse<T>,
		method: string = 'get',
		addHeaders?: (headers: Headers) => void,
		getBody?: () => any,
		newBaseUrl?: string
	): Promise<T> {
		return new Promise(async (resolve, reject) => {
			try {
				if (!this.authProvider) throw new Error(`No token provider specified`)

				let params = getParams()
				if (!params) params = {}

				const qs = '?' + querystring.stringify(params)
				const fullUrl = `${newBaseUrl ?? baseUrl}${url}${qs}`
				log('fullUrl', fullUrl)

				let headers = new Headers()
				if (addHeaders) addHeaders(headers)
				headers.append('Content-Type', 'text/json')

				const token = await this.authProvider.getAccessToken(SCOPES)
				headers.append('Authorization', 'Bearer ' + token)

				let opts: any = { headers: headers, method: method }
				if (getBody) opts.body = getBody()

				const res = await fetch(fullUrl, opts)

				if (res.ok) {
					const result = await processResponse(res)
					resolve(result)
				} else {
					throw new Error(await res.text())
				}
			} catch (error) {
				log.error(`Error in ${url}:`, error)
				reject(error)
			}
		})
	}
}
