import React from 'react'
import anylogger from '@app/anylogger'
import { Image } from './Image'

const log = anylogger('OptimizedImage')

interface OptimizedImageProps {}
export const OptimizedImage = React.forwardRef(function OptimizedImage(props: any, ref: any) {
	let { width, height, src, alt, float, maxWidth, ...rest } = props
	let sx = {}
	if (float) {
		sx = { ...sx, float }
		if (!maxWidth) maxWidth = '50%'
	}

	const convertToNum = (val: number | string) => {
		let res = val
		if (typeof val == 'string') {
			if (val.includes('%')) res = 0
			else res = parseInt(val)
		}
		return res
	}
	let w = convertToNum(width)
	let h = convertToNum(height)
	let resize = ''
	if (w && h) {
		resize = `?resize=crop&w=${w}&h=${h}`
	} else if (w || h) {
		if (w) resize = `?resize=resize&w=${w}`
		else resize = `?resize=resize&h=${h}`
	} else resize = resize = `?resize=resize&w=600`
	const newSrc = src + resize
	if (typeof width == 'number') width = `${width}px`
	if (typeof height == 'number') height = `${height}px`

	return <Image src={newSrc} width={width} height={height} maxWidth={maxWidth} alt={alt} {...rest} sx={{ ...sx }} />
})
