import React, { ReactElement, ReactNode } from 'react'
import anylogger from '@app/anylogger'
import { Flex, H4, PaperFlex, Text } from '@app/muiplus'
import { PageReferenceData } from '@app/mdxsource'
import { Box, Paper } from '@mui/material'
import { Image } from './Image'
import Link from './Link'
import { useRouter } from 'next/router'
import { useThemeProps } from '@mui/material'
import { styled } from '@mui/material'

const log = anylogger('Card')

interface CardProps {
	pageData?: PageReferenceData
	image?: string
	title?: string
	description?: React.ReactNode
	// description?: string  // this is not needed.  We either get the description from the pageData or from the "children"
	link?: string
	maxWidth?: string
	children?: React.ReactNode
	picPos?: 'left' | 'right' | 'top' | 'bottom'
	picPercent?: number
	[key: string]: any
}
const Card = React.forwardRef(function Card(inProps: CardProps, ref: any) {
	const props = useThemeProps({ props: inProps, name: 'PageCard' })
	let { picPos = 'top', picPercent = 50, maxWidth = '500px', title, image, link, description, children, pageData, ...rest } = props
	const router = useRouter()

	link = link || pageData?.link
	title = title || pageData?.title
	image = image || pageData?.summaryImage
	description = description || pageData?.summary || children

	const cardProps: any = {}

	const isLeftRight = picPos === 'left' || picPos === 'right'

	// default to reasonably large size if we can't derive the width of the card
	let calculatedWidth = 600
	if (!maxWidth?.endsWith('%')) {
		// this will get the integer portion of '500px', returning 500
		const val = parseInt(maxWidth)
		if (!Number.isNaN(val)) {
			calculatedWidth = val

			calculatedWidth = parseInt(maxWidth)

			// add px to the end if only a number is specified
			if (calculatedWidth.toString() == maxWidth) maxWidth = maxWidth + 'px'
		}
	}

	// this assumes that we are using image caching Cloud Run service  which can return a requested image size
	if (image) {
		let imageWidth = calculatedWidth
		if (calculatedWidth && isLeftRight && picPercent) {
			// calculate the image width based on the card width and the pic percent (ignoring any margins or padding)
			imageWidth = Math.trunc((calculatedWidth * picPercent) / 100)
		}
		image = image + `?resize=resize&w=${imageWidth}`
	}

	if (link) {
		cardProps['href'] = link
		cardProps['cursor'] = 'pointer'
	}

	let f = 'c'
	let flexGrow = ''
	const imageProps: any = {}
	const textProps: any = {}
	if (isLeftRight) {
		f = 'r'
		const textPercent = 100 - picPercent
		imageProps.flex = `1 1 ${picPercent}%`
		textProps.flex = `1 1 ${textPercent}%`
		textProps.m = '10px'
	} else {
		f = 'c'
		imageProps.flex = `1 1`
		textProps.flex = `1 1`
		textProps.m = '10px'
	}

	const getLink = (content: ReactNode): ReactNode => {
		if (link) {
			return (
				// href is declared empty to get rid of an error, but is overwridden in ...cardProps
				<Link href="" {...cardProps} underline="none" variant="body1">
					{/* We just use a Link (renamed as ThemedLink) here so that we can get rid of the link underline for
					the text but we do not specify the cardProps (which contain the href and cursor) so that the Next
					Link is guarenteed to use them */}
					{/* <Link {...cardProps} variant="noLink"> */}
					{content}
					{/* </Link> */}
				</Link>
			)
		} else return content
	}

	const getTitle = () => {
		if (!title) return undefined
		return getLink(
			<Flex fill justifyContent="center">
				<b>{title}</b>
			</Flex>
		)
	}
	const getDescription = () => {
		return getLink(description)
	}
	const getText = () => {
		return (
			<Box {...textProps}>
				{getTitle()}
				{getDescription()}
			</Box>
		)
	}
	const getImage = () => {
		if (!image) return null
		return <Box {...imageProps}>{getLink(<Image m="0px" width="100%" height="100%" src={image} alt={title} />)}</Box>
	}
	const getContent = () => {
		if (picPos === 'right' || picPos === 'bottom')
			return (
				<>
					{getText()}
					{getImage()}
				</>
			)
		else
			return (
				<>
					{getImage()}
					{getText()}
				</>
			)
	}
	const click = () => {
		log('click', link)

		if (link) router.push(link)
	}

	return (
		<PaperFlex
			elevation={10}
			sx={{ borderRadius: '10px' }}
			f={f}
			fill
			alignItems="center"
			justifyContent="spaceBetween"
			maxWidth={maxWidth}
			// if we put this, then the image does not have rounded corners on the top
			// p="0.25em"
			{...rest}
		>
			{getContent()}
		</PaperFlex>
	)
})
export { Card }
