import { H5, ContrastBackgroundBox } from '@app/muiplus'

function Error404() {
	return (
		<ContrastBackgroundBox m="auto" p="1em" borderRadius="50%/100%">
			<H5>Well this is embarassing. This page seems to have disappeared or to never have existed.</H5>
		</ContrastBackgroundBox>
	)
}

export { Error404 }
