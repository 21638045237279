import anylogger from '@app/anylogger'

export const Bucket = 'bucket'
export const Dir = 'dir'
export const Obj = 'obj'
export type ObjectType = typeof Bucket | typeof Dir | typeof Obj | ''

export class ICloudObject {
	bucketId: string = ''
	id: string = ''
	path: string = ''
	type: ObjectType = ''
	name: string = ''
	size: number = 0
	contentType: string = ''
	etag: string = ''
	updated: string = ''
	created: string = ''
	metadata: any = undefined
	isDir: boolean = false
	directLink?: string
}
export type ICloudObjectList = ICloudObject[]
export type CloudObjectKey = keyof ICloudObject

export type ICloudObjectKey = Partial<Pick<ICloudObject, 'bucketId'>> & Pick<ICloudObject, 'id'>

export interface BucketParams {
	bucketId?: string
}
export interface PathParams extends BucketParams {
	path: string
}
export interface AddParams extends BucketParams {
	name: string // this is the full path of the file to be added
	contentType: string
	base64: string
	metadata?: any
}
export interface UpdateParams {
	contentType?: string
	base64?: string
	metadata?: any
}

export interface PagedRequestParams {
	pageSize: number
	pageToken?: string
}
export interface PagedResults {
	pageToken?: string
	list: ICloudObjectList
}

/**!
 * ! Changes:
 * * merge all XXX and XXXByPath methods to accept either a pathParams or Pick<ICloudStorage, 'id'|'bucketId'> object
 * * merge getChidren and getDirectory into getChildren accepting either path parms or an object
 * ** Add tests for the methods that don't already have ByPath methods, such as getContent and deleteObject
 * * fix UpdateObject (and add tests) to allow updating of content
 * * clarify how AddObject works so that it does not create a duplicate named object in Google Drive, and does not overwrite an object in Google Cloud Storage
 * ** Add tests for this
 * * Add paging capabilities for getAll and getChildren, either returning the list itself if paging not requested, or a continuation object if paging is requested
 */
export class ICloudStorage {
	// Capabilities
	supportsFreeFormMetadata!: () => boolean

	getBuckets!: () => Promise<ICloudObjectList>
	getAll!: (params?: BucketParams, pageParams?: PagedRequestParams) => Promise<PagedResults>
	getDirectory!: (params: PathParams, pageParams?: PagedRequestParams) => Promise<PagedResults>
	getChildren!: (obj: ICloudObjectKey, pageParams?: PagedRequestParams) => Promise<PagedResults>

	getObject!: (obj: ICloudObjectKey) => Promise<ICloudObject | undefined>
	getObjectByPath!: (params: PathParams) => Promise<ICloudObject | undefined>

	//! Should this just be updateMetadata?
	updateObject!: (obj: ICloudObjectKey, params: UpdateParams) => Promise<ICloudObject>
	updateObjectByPath!: (pathParams: PathParams, params: UpdateParams) => Promise<ICloudObject>

	getContent!: (obj: ICloudObjectKey) => Promise<string>
	getStream!: (obj: ICloudObjectKey) => Promise<Response>
	getStreamByPath!: (params: PathParams) => Promise<Response>

	//! Should this be upsertObject? At least in GCS, an insert always replaces the existing object.  Google Drive creates duplicate, identically named objects
	//! Or should it throw an error if the object already exists
	addObject!: (params: AddParams) => Promise<ICloudObject>

	// addOrUpdateObject!: (params: AddParams): Promise<ICloudObject>
	deleteObject!: (obj: ICloudObjectKey) => Promise<void>
}
