import React from 'react'
import anylogger from '@app/anylogger'
import { ITreeNodeActions, ITreeNodeData } from '@app/muiplus'
import { ICloudObject, ICloudObjectKey, ICloudObjectList, ICloudStorage, Obj } from '@app/cloudstorage'

const log = anylogger('CloudStorageTreeViewAdapter')

export class CloudStorageTreeViewAdapter implements ITreeNodeData {
	api: ICloudStorage
	data?: Partial<ICloudObject>
	id: string
	label: string
	parent: CloudStorageTreeViewAdapter | undefined
	actions?: ITreeNodeActions

	static createAdapter(api: ICloudStorage, obj?: Partial<ICloudObject>) {
		return new CloudStorageTreeViewAdapter(api, obj)
	}

	static createList(api: ICloudStorage, items: ICloudObjectList, parent?: CloudStorageTreeViewAdapter) {
		return items.map((item) => {
			return new CloudStorageTreeViewAdapter(api, item, parent)
		})
	}
	constructor(api: ICloudStorage, data?: Partial<ICloudObject>, parent?: CloudStorageTreeViewAdapter) {
		this.api = api
		this.data = data
		this.parent = parent
		if (!data) {
			this.label = 'Buckets'
			this.id = 'Buckets'
		} else {
			this.id = data.id ?? 'NoId'
			this.label = this.getName(data)
		}
	}
	getName(data: Partial<ICloudObject>): string {
		let name = data.name
		if (!name) return 'NoName'
		if (name.endsWith('/')) name = name.slice(0, name.length - 1)
		const vals = name.split('/')
		name = vals[vals.length - 1]
		return name
	}
	async getChildren() {
		let vals: ICloudObjectList = []
		// for the Object type (or if type is not defined) return no children.
		// Buckets and Directory types (as well as an undefined type) will proceed to query the children
		if (typeof this.data?.type != 'undefined' && this.data.type == Obj) {
			return Promise.resolve([])
		}

		if (!this.data) vals = await this.api.getBuckets()
		else {
			const res = await this.api.getChildren(this.data as ICloudObjectKey)
			vals = res.list
		}

		return Promise.resolve(CloudStorageTreeViewAdapter.createList(this.api, vals))
	}
	setActions(actions: ITreeNodeActions) {
		this.actions = actions
	}
	expand() {
		if (this.actions) this.actions.setExpanded(true)
	}
}
