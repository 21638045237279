import React from 'react'
import anylogger from '@app/anylogger'
import { ContrastBackgroundBox, H5 } from '@app/muiplus'

const log = anylogger('Loading')

interface ErrorMessageProps {
	message: string
}
function ErrorMessage(props: ErrorMessageProps) {
	const { message } = props

	return (
		<ContrastBackgroundBox m="auto" p="1em" borderRadius="50%/100%">
			<H5>{message}</H5>
		</ContrastBackgroundBox>
	)
}
export { ErrorMessage }
