import React, { useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
import { GeneralLayout } from 'components/GeneralLayout'
import { Centered, IMenuList, MenuContext, H5 } from '@app/muiplus'
import { useSession } from 'src/services/useSession'
import { MdxSource } from '@app/mdxsource'
import { WebMenu, WebMenuItem } from '@app/utils'
import { GetStaticProps } from 'next'
import { Error404 } from '@app/uiutils'
import { serialize } from 'next-mdx-remote/serialize'
import { MdxView } from 'components/MdxView'
import { getMdxSource, getMemberMenu } from '@app/easymdx'
import { getCombinedMenu } from 'src/services/getCombinedMenu'
import { filterMemberMenu } from 'src/services/filterMemberMenu'
import remarkGfm from 'remark-gfm'
import { useRouter } from 'next/router'
import CalendarController from 'components/Calendar'

const log = anylogger('paths')

// log('config', config)

export async function getStaticPaths() {
	const src = await getMdxSource()
	const paths = src.GetPaths()
	// log.info('getStatic Paths:', paths.join(', '))

	const paramPaths = paths.map((path) => {
		// each path is an array of directory entries for the file
		// i.e. ['About', 'Contact Us']
		// the paramPaths is an array of these path entries, which will create a "page" for each path in paramPaths
		return {
			params: {
				paths: path
			}
		}
	})
	paramPaths.push({ params: { paths: ['Calendar'] } })

	// We'll pre-render only these paths at build time.
	// { fallback: false } means other routes should 404.
	// { fallback: true } means all other url paths will be passed to getStaticProps to try to rosolve them
	// { fallback: blocking} means that it will run getStaticProps bofore returning a result, making the response slower, but preventing a "Not Found" page, while the
	// page is being calculated.
	return { paths: paramPaths, fallback: 'blocking' }
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
	let menu: WebMenu = []
	let memberMenuItems: WebMenu = []

	const createResponse = (props: any) => {
		return { props: { ...props, menu, memberMenuItems } }
	}

	try {
		log.info('getStatic Props:', params)

		let paths: string[]
		if (!params?.paths) paths = ['Home']
		else paths = params?.paths as string[]

		const src = await getMdxSource()
		menu = src.GetMenu()
		menu.splice(1, 0, { caption: 'Calendar', url: '/Calendar' })

		memberMenuItems = getMemberMenu()

		const entry = src.FindEntry(paths)

		if (!entry) return createResponse({ notFound: true })

		const rawContent = await src.GetContent(entry)

		let meta: any = {}
		let serialized: any = ''
		let scope: any = { ...meta }

		const useFineApi = true

		if (useFineApi) {
			if (!rawContent) return createResponse({ notFound: true })
			let parsed = await src.ParseContent(rawContent)
			let { content } = parsed
			meta = parsed.meta

			content = src.ApplyPrependedContent(content, meta)

			const childData = await src.GetChildData(entry)
			scope = { ...scope, childData }
			// log('childData', childData)

			const pageData = await src.GetPageData(meta.pageData)
			if (Array.isArray(pageData)) scope.pageData = pageData
			// log('pageData', pageData)

			serialized = await serialize(content, {
				scope,
				mdxOptions: {
					remarkPlugins: [remarkGfm],
					rehypePlugins: []
				}
			})
		} else {
			// const { meta, content } = await src.GetParsedContent(entry)
			// scope can be childData, referencedPages, frontMatter objects
			// const { meta, content, serialized, scope } = src.ProcessContent(rawContent, { applyPrepends: true })
		}

		return createResponse({ source: serialized, meta })
	} catch (err) {
		log.error(err)
		return createResponse({ error: 'Exception: ' + err })
	}
}

const MDXContent = React.forwardRef(function MDXContent(props: any, ref: any) {
	let { notFound, error, meta, source, menu: webMenu = [], memberMenuItems = [] } = props
	const router = useRouter()
	const [memberMenu, setMemberMenu] = useState<WebMenu>([])

	const [session, loading] = useSession()
	useEffect(() => {
		setMemberMenu(filterMemberMenu(session?.roles, memberMenuItems))
	}, [memberMenuItems, session?.roles, filterMemberMenu])

	const menu = getCombinedMenu(session, webMenu, memberMenu)

	let content
	if (error) {
		log('error', error)

		content = (
			<Centered>
				<H5>{error}</H5>
			</Centered>
		)
	} else if (router.asPath == '/Calendar') {
		content = <CalendarController></CalendarController>
	} else if (!source || notFound) {
		content = <Error404 />
	} else content = <MdxView source={source} data={meta} />

	const menuContext = { items: menu }
	return (
		<MenuContext.Provider value={menuContext}>
			<GeneralLayout>{content}</GeneralLayout>
		</MenuContext.Provider>
	)
	// return (
	// 	<MenuOverlay router={router} menu={menu}>
	// 		<PublicLayout>
	// 			<MdxView source={source} data={data} />
	// 		</PublicLayout>
	// 	</MenuOverlay>
	// )
})
export default MDXContent
